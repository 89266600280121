// main ../style.scss

.modal-ring {
  position: relative;
  width: 480px;
  height: 523px;
  border-radius: 8px;
  background-color: $white;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
  padding: 60px 72px 40px 48px;
  text-align: center;
  transition: opacity $modal-transition-settings, transform $modal-transition-settings, visibility $modal-transition-settings;

  @include w-768 {
    width: 320px;
    border-radius: 0;
    height: 568px;
    padding: 75px 16px;
  }

  // .modal-ring_hide

  &_hide {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    border: 0;
    clip: rect(0 0 0 0);
  }

  // .modal-ring__title

  &__title {
    color: $blue;
    font-family: $mont;
    font-weight: 700;
    letter-spacing: -0.56px;
    font-size: 28px;
    text-align: left;
    margin-top: 0;
    margin-bottom: 25px;

    @include w-768 {
      font-size: 24px;
      letter-spacing: -0.48px;
      width: auto;
      text-align: center;
    }
  }

  // .modal-ring__sub-title

  &__sub-title {
    opacity: 0.87;
    line-height: 25.5px;
    text-align: left;
    margin-right: 10px;
    margin-bottom: 14px;
    font-weight: 400;

    @include w-768 {
      line-height: 22.4px;
      font-size: 14px;
      text-align: center;
      max-width: 257px;
      margin: 0 auto 7px auto;
    }
  }

  // .modal-ring__check

  &__check {
    max-width: 264px;
    margin-left: 60px;

    @include w-768 {
      margin-left: 12px;
    }

    & input {
      position: absolute;
      z-index: -1;
      opacity: 0;
    }

    & label {
      display: inline-flex;
      align-items: center;
      user-select: none;
      opacity: 0.74;
      font-size: 13px;
      font-weight: 400;
      line-height: 19.5px;
      z-index: 100;
      position: relative;
      cursor: pointer;
    }

    & label::before {
      content: "";
      display: inline-block;
      width: 16px;
      height: 16px;
      flex-shrink: 0;
      flex-grow: 0;
      border: 2px solid $yellow;
      border-radius: 2px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50% 50%;
      margin-bottom: 12px;
      z-index: 100;
    }

    & input:checked + label::before {
      border-color: $yellow;
      background-color: $yellow;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    }

    /* стили при наведении курсора на checkbox */
    & input:not(:disabled):not(:checked) + label:hover::before {
      opacity: 0.8;
    }
    /* стили для активного состояния чекбокса (при нажатии на него) */
    & input:not(:disabled):active + label::before {
      background-color: rgba(7, 7, 7, 0.596);
      border-color: rgba(7, 7, 7, 0.596);
    }
    /* стили для чекбокса, находящегося в фокусе */
    & input:focus + label::before {
      box-shadow: 0 0 0 2px rgba(7, 7, 7, 0.596);
    }
    /* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
    & input:focus:not(:checked) + label::before {
      border-color: $black;
    }
    /* стили для чекбокса, находящегося в состоянии disabled */
    & input:disabled + label::before {
      background-color: $yellow;
    }
  }

  // .modal-ring__cls-btn-link

  &__cls-btn-link {
    position: absolute;
    top: 35px;
    right: 35px;
    cursor: pointer;
    background: $transparent;
    border: none;

    &:focus {
      outline: none;
      border: 1px $blue solid;
    }

    @include w-768 {
      top: 24px;
      right: 24px;
    }
  }

  &__cls-btn-img:hover {
    opacity: 0.8;
  }
}

.modal-ring__form {
  // inputs string

  & input[type="text"] {
    width: 360px;
    height: 59px;
    border-radius: 80px;
    padding: 19px 0 20px 40px;
    margin-bottom: 8px;
    opacity: 0.87;
    font-size: 17px;
    font-weight: 500;
    color: $black;

    &:focus {
      border: 2px solid $yellow;
      outline: none;
    }

    @include w-768 {
      width: 288px;
    }
  }

  // send button

  & button {
    width: 264px;
    height: 59px;
    border-radius: 80px;
    background: $grad;
    margin: 0 0 12px 30px;
    border: none;
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.12);
    color: $white;
    font-size: 17px;
    font-weight: 500;
    cursor: pointer;

    &:focus {
      border: 2px $blue solid;
      outline: none;
    }

    &:hover {
      background: $yellow;
    }

    @include w-768 {
      margin-left: 5px;
    }
  }

  // modal-ring__form_error

  &_error {
    border-color: $red;
    height: 200px;
  }

  // modal-ring__form_right-box

  &_right-box {
    color: $black;
  }

  // modal-ring__form_error-box

  &_error-box {
    color: $red;
  }
}
