// main: ../style.scss

.masa {
  background-image: url("../img/bg/meeting.jpg");
  background-repeat: no-repeat;

  @include retina {
    background-image: url("../img/bg/meeting@2x.jpg");
    background-size: 1360px;
  }

  @include w-768 {
    background-size: 200%;
    background-position-x: -80px;
    background-position-y: 40%;
  }

  @include w-320 {
    background-position-y: 40px;
  }

  // .masa_position

  &_position {
    max-width: 1366px;
    margin: 0 auto;
    min-height: 583px;

    @include w-768 {
      min-height: 421px;
    }
  }

  // .masa__content

  &__content {
    position: relative;
    top: 145px;
    left: 800px;
    width: 395px;
    height: 267px;

    @include w-1366 {
      left: 58.5%;
    }

    @include w-1024 {
      left: 45%;
    }

    @include w-768 {
      width: 300px;
      left: 0;
      top: 0;
      margin: 51px auto 0 auto;
    }
  }

  // .masa__title

  &__title {
    margin-bottom: 35px;

    @include w-768 {
      text-align: center;
      margin-bottom: 95px;
      font-size: 40px;
      line-height: 49px;
    }
  }

  // .masa__sub-title

  &__sub-title {
    opacity: 0.87;
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.12);
    color: $white;
    font-size: 17px;
    line-height: 28.9px;

    @include w-768 {
      max-width: 272px;
      margin: 0 auto;
      line-height: 26px
    }
  }

  // .masa__link

  &__link {
    width: 230px;
    height: 59px;
    display: flex;
    text-decoration: none;
    padding: 19px 38px;
    border-radius: 80px;
    background: $grad;
    align-items: center;
    margin-top: 48px;

    @include w-768 {
      width: 264px;
      margin: 58px auto;
    }

    &:focus {
      border-radius: 150px;
      box-shadow: 2px 2px 0 $blue, -2px -2px 0 $blue, -2px 2px 0 $blue, 2px -2px 0 $blue;
      outline: none;
    }

    &:hover {
      background: $yellow;
    }
  }

  // .masa__link-img

  &__link-img {
    margin-right: 16px;
  }

  // .masa__link-text

  &__link-text {
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.12);
    color: $white;
    font-size: 17px;
    font-weight: 500;
    white-space: nowrap;
  }
}
