// main: ../style.scss

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  z-index: 1;
  position: relative;
  height: 52px;
  margin: 32px auto;

  @include w-768 {
    padding: 0 13px;
    flex-flow: column wrap;
    align-items: space-between;
    justify-content: start;
    margin: 15px auto;
  }

  @include w-320 {
    padding: 0;
  }

  // .menu__number

  &__number {
    font-weight: 500;
    text-decoration: none;
    color: $black;
    white-space: nowrap;

    transition: ease-in-out $t-anim;

    &:hover {
      color: black;
      text-decoration: underline;
    }

    @include w-768 {
      order: 2;
      font-size: 14px;
    }
  }

  // .menu__logo

  &__logo {
    width: 137px;
    height: 48px;

    @include w-768 {
      width: 120px;
      height: 42px;
      margin-right: 30px;
    }
  }

  // .menu__ring-me

  &__ring-me {
    border: none;
    height: 22px;
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;
    color: $black;
    font-family: $sans;
    border-bottom: 1px dashed $black;
    text-transform: uppercase;
    cursor: pointer;
    white-space: nowrap;
    transition: ease-in-out $t-anim;

    &:hover {
      color: black;
    }

    @include w-768 {
      order: 3;
      font-size: 14px;
      text-transform: none;
      margin-left: 15px;
    }
  }
}
