// main ../style.scss

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  overflow-y: auto;

  background-color: $overlay;
  visibility: hidden;
  opacity: 0;

  transition: opacity $modal-transition-settings,
    transform $modal-transition-settings,
    visibility $modal-transition-settings;

  &_visible {
    opacity: 1;
    visibility: visible;
  }

  // .modal__wrapper

  &__wrapper {
    position: relative;

    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100%;
    padding: 60px 40px;

    @include w-768 {
      padding: 60px 16px;
    }
  }

  // .modal__overlay

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    cursor: pointer;
  }
}

.no-scroll {
  height: 100%;
  overflow: hidden;
}

.form-right {
  border: 2px solid $grey;
}

// .error-text

.error-text {
  color: $red;
  margin: 0;
  font-size: 13px;
  line-height: 150%;
  opacity: 0.75;
  visibility: hidden;

  // .error-text_visible

  &_visible {
    visibility: visible;
  }
}
