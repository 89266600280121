// main: ../style.scss

.title {
  color: $blue;
  font-family: $mont;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: -0.8px;
  text-transform: uppercase;

  @include w-768 {
    font-size: 32px;
    letter-spacing: -0.64px;
  }
}

.sub-title {
  font-size: 17px;
  font-weight: 400;
  line-height: 28.9px;

  @include w-768 {
    font-size: 16px;
    line-height: 25.6px;
  }
}
