.want-way {
  background-image: url("../img/bg/girl-flag-bg.jpg");
  background-repeat: no-repeat;
  background-position-x: 550px;

  @include w-1024 {
    display: flex;
    flex-flow: column;
    background-position-x: 50%;
    background-position-y: 100%;
    background-size: 130%;
  }

  @include w-768 {
    background-size: 90%;
  }

  @include w-320 {
    background-size: 130%;
  }

  @include retina {
    background-image: url("../img/bg/girl-flag-bg@2x.jpg");
    background-size: 820px;

    @include w-1024 {
      background-size: 100%;
    }

    @include w-768 {
      background-size: 90%;
    }

    @include w-320 {
      background-size: 130%;
    }
  }
  // .want-way_position

  &_position {
    min-height: 594px;
    width: 100%;
    margin: 0 auto;
    padding-top: 145px;
    max-width: 1366px;

    @include w-1024 {
      min-height: 900px;
      padding-top: 0;
    }

    @include w-768 {
      min-height: 660px;
      padding-top: 0;
      margin-top: 90px;
    }
  }

  // .want-way__form

  &__form {
    max-width: 1366px;
    min-height: 328px;
    margin: 0 auto;
    width: 100%;

    @include w-1024 {
      max-width: 100%;
    }
  }

  // .want-way__form-bg

  &__form-bg {
    background-image: url("../img/bg/backpack.jpg");
    background-repeat: no-repeat;
    background-position-x: -60px;
    padding-top: 40px;

    @include retina {
      background-image: url("../img/bg/backpack@2x.jpg");
      background-size: 170px;
    }
  }

  // .want-way__form-content

  &__form-content {
    width: 477px;
    margin-left: 210px;

    @include w-1024 {
      margin-top: 40px;
    }

    @include w-768 {
      width: 286px;
      margin: 40px auto;
    }
  }

  // .want-way__sub-title

  &__sub-title {
    max-width: 400px;
    line-height: 28.9px;

    @include w-768 {
      width: 286px;
    }
  }

  // .want-way__form-container

  &__form-container {
    position: relative;
    margin-top: 64px;

    @include w-768 {
      margin-top: 20px;
    }
  }

  // .want-way__phone-input

  &__phone-input {
    border: 2px solid #e3e3e3;
    border-radius: 150px;
    padding: 19px 193px 20px 40px;

    &:focus {
      border-radius: 150px;
      border: 2px $yellow solid;
      outline: none;
    }

    &::placeholder {
      color: $black;
      text-transform: uppercase;
      opacity: 0.5;
    }

    @include w-768 {
      width: 287px;
      padding-right: 40px;
    }

    // .want-way__phone-input_error

    &_error {
      border: 2px $red solid;
    }
  }

  // .want-way__phone-btn

  &__phone-btn {
    position: absolute;
    top: 0;
    left: 55%;
    padding: 20px 40px 20px 40px;
    border-radius: 150px;
    background: $grad;
    color: $white;
    text-transform: uppercase;
    border: none;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    cursor: pointer;
    height: 60px;
    white-space: nowrap;

    &:focus {
      border-radius: 150px;
      box-shadow: 2px 2px 0 $blue, -2px -2px 0 $blue, -2px 2px 0 $blue, 2px -2px 0 $blue;
      outline: none;
    }

    &:hover {
      background: $yellow;
    }

    @include w-768 {
      width: 264px;
      position: relative;
      left: 0;
      margin: 8px 11px;
    }
  }

  // .want-way__error

  &__error {
    color: $red;
    position: absolute;
    top: -25px;
    left: 60px;
    visibility: hidden;

    // .want-way__error_display

    &_display {
      visibility: visible;
    }
  }
}
