// main: ../style.scss

.footer {
  max-width: 1366px;
  width: 100%;
  height: 75px;
  border-top: 1px solid rgba($color: $black, $alpha: 0.2);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;

  @include w-768 {
    height: 197px;
    flex-flow: column;
    justify-content: space-evenly;
  }

  // .footer__logo-link

  &__logo-link {
    margin-left: 110px;

    @include w-768 {
      margin: 0;
    }
  }

  // .footer__copyright

  &__copyright {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    opacity: 0.5;
  }

  // .footer__soc-block

  &__soc-block {
    display: flex;
    margin-right: 114px;

    @include w-768 {
      margin: 0;
      margin-bottom: 10px;
      margin-right: 10px;
    }
  }

  // .footer__soc-link

  &__soc-link {
    margin: 0 10px;

    @include w-768 {
      margin: 0 14px;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      stroke: $yellow;
      stroke-width: 2px;
    }
  }
}
