// main: ../style.scss

.programs {
  background-image: url("../img/bg/programs_bg.jpg");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 80px;
  padding: 104px 0 31px 0;

  @include retina {
    background-image: url("../img/bg/programs_bg@2x.jpg");
    background-size: 678px;
  }

  @include w-768 {
    background-size: contain;
    background-position-y: 250px;
  }

  // .programs_position

  &_position {
    max-width: 1366px;
    padding-left: 40px;
    margin: 0 auto;

    @include w-768 {
      margin-left: 0;
      padding-left: 0;
      margin-bottom: 100px;
      padding-top: 55px;
    }
  }

  // .programs__title

  &__title {
    margin-bottom: 78px;
    margin-left: 95px;

    @include w-768 {
      margin-left: 0;
      width: 100%;
      text-align: center;
      margin-bottom: 24px;
    }
  }

  // .programs__main-block

  &__main-block {
    display: flex;
    margin-left: 95px;

    @include w-768 {
      flex-flow: column;
      margin-left: 0;
    }
  }

  // .programs__swiper-container

  &__swiper-container {
    width: 300px;
    margin: 0;

    @include w-1024 {
      width: 335px;
    }

    @include w-768 {
      max-width: 690px;
      width: 100%;
    }
  }

  // .programs__swiper-wrapper

  &__swiper-wrapper {
    display: flex;
    flex-flow: column;

    @include w-768 {
      flex-flow: row;
    }
  }

  // .programs__swiper-slide

  &__swiper-slide {
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    font-variant: small-caps;
    text-shadow: 0 1.01117px 4.04469px rgba(0, 0, 0, 0.12);
    opacity: 0.87;
    height: 68px;
    padding: 16px 20px;
    cursor: pointer;
    width: 99%;
    border: none;
    background-color: $transparent;

    &:active {
      outline: none;
    }

    &:hover span {
      color: $yellow;
    }

    &:hover .programs__swiper-icon {
      background-position-y: 34px;
    }

    @include w-768 {
      justify-content: center;
      height: 48px;
      border-radius: 60.6704px;
      width: 100%;
      padding: 0;
    }

    // .programs__swiper-slide_active

    &_active {
      background-image: url("../img/bg/arrow-bg.png");
      color: white;
      opacity: 1;
      width: 228px;

      &:focus {
        outline: none;
      }

      &:hover span {
        color: white;
      }

      &:hover .programs__swiper-icon {
        background-position-y: 0;
      }

      @include w-768 {
        background: $grad;
      }
    }
  }

  // .programs__swiper-icon

  &__swiper-icon {
    margin-right: 8px;
    background-image: url("../img/content/icons.png");
    background-position-y: 77px;
    display: block;

    // .programs__swiper-icon_active-style

    &_active-style {
      background-position-y: 0;
    }
  }

  // .programs__swiper-star

  &__swiper-star {
    width: 28px;
    height: 33px;
    background-position-x: 0;
  }

  // .programs__swiper-hat

  &__swiper-hat {
    width: 36px;
    height: 34px;
    background-position-x: -45px;
    margin-right: 2px;
  }

  // .programs__swiper-case

  &__swiper-case {
    width: 32px;
    height: 45px;
    background-position-x: -96px;
  }

  // .programs__swiper-hand

  &__swiper-hand {
    width: 32px;
    height: 45px;
    background-position-x: -143px;
  }

  // .programs__swiper-glass

  &__swiper-glass {
    width: 25px;
    height: 45px;
    background-position-x: -190px;
  }

  // .programs__info

  &__info {
    position: relative;
    width: 560px;
    min-height: 288px;
    height: auto;

    @include w-768 {
      width: 95%;
      margin: 15px 16px 0 16px;
    }
  }

  // .programs__info-container

  &__info-container {
    display: block;
    transition: $default-transition-settings;

    // .programs__info-container_hide

    &_hide {
      display: none;
    }
  }

  // .programs__info-title

  &__info-title {
    font-size: 28px;
    letter-spacing: -0.56px;
    margin-top: 20px;
    text-transform: none;
    margin-bottom: 35px;

    @include w-768 {
      font-size: 24px;
      letter-spacing: -0.48px;
    }
  }

  // .programs__info-text

  &__info-text {
    opacity: 0.87;
    line-height: 25.5px;

    @include w-768 {
      line-height: 22px;
    }
  }
}
