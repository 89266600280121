// main: style.scss

.container {
  max-width: 1556px;
  width: 100%;
  padding: 0 80px;

  @include w-1366 {
    padding: 0 15px;
  }

  @include w-768 {
    max-width: 768px;
    padding: 0 10px;
  }

  @include w-320 {
    max-width: 320px;
    padding: 0 3px;
  }
}
