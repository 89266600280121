// main: style.scss

@font-face {
  font-family: "Raleway";
  src: url("../fonts/Raleway-Bold.woff2") format("woff2");
  src: url("../fonts/Raleway-Bold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: url("../fonts/Raleway-ExtraBold.woff2") format("woff2");
  src: url("../fonts/Raleway-ExtraBold.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "FiraSans";
  src: url("../fonts/FiraSans-Regular.woff2") format("woff2");
  src: url("../fonts/FiraSans-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "FiraSans";
  src: url("../fonts/FiraSans-Medium.woff2") format("woff2");
  src: url("../fonts/FiraSans-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "FiraSans";
  src: url("../fonts/FiraSans-Bold.woff2") format("woff2");
  src: url("../fonts/FiraSans-Bold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Bold.woff2") format("woff2");
  src: url("../fonts/Montserrat-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
