// main: ../style.scss

.main {
  min-height: 850px;
  max-width: 1366px;
  margin: 0 auto;

  @include w-1024 {
    min-height: 687px;
  }

  @include w-768 {
    min-height: 595px;
  }
  // .main__bg

  &__bg {
    position: relative;
    background-image: url("../img/bg/bg.jpg");
    background-repeat: no-repeat;
    background-position-y: -30px;
    background-position-x: center;
    background-size: cover;
    display: block;

    @include retina {
      background-image: url("../img/bg/bg@2x.jpg");
    }

    @include w-1024 {
      background-size: 1024px auto;
      background-position-y: 0;
    }

    @include w-768 {
      background-image: url("../img/bg/bg_mobile.jpg");
      background-size: 880px;
      background-position-y: 20px;

      @include retina {
        background-image: url("../img/bg/bg_mobile@2x.jpg");
      }
    }
  }

  // .main__body

  &__body {
    position: relative;
  }

  // .main__content

  &__content {
    max-width: 343px;
    text-align: center;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 405px 0 0 0;
    margin: 0 auto;

    @include w-1024 {
      padding-top: 300px;
    }

    @include w-768 {
      padding-top: 235px;
    }
  }

  // .main__title

  &__sub-title {
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.12);
    color: #ffffff;
    font-size: 24px;
    font-weight: 700;
    display: inline-block;
    margin-bottom: 35px;
    width: 343px;

    @include w-768 {
      margin-bottom: 30px;
      font-size: 20px;
      width: 297px;
    }
  }

  // .main__scroll-link

  &__scroll-link {
    transition: ease-in-out $t-anim;
    cursor: pointer;
  }

  // .main__scroll-img

  &__scroll-img {
    background-image: url("../img/content/mouse.png");
    background-repeat: no-repeat;
    background-position-x: 29px;
    background-position-y: 25px;
    background-size: 19px;

    @include retina {
      background-image: url("../img/content/mouse@2x.png");
    }
  }
}
