// main: ../style.scss

.modal-accept {
  position: relative;
  width: 480px;
  height: 523px;
  border-radius: 8px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: $white;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
  padding: 60px 72px 40px 70px;
  transition: opacity $modal-transition-settings,
    transform $modal-transition-settings,
    visibility $modal-transition-settings;

  @include w-768 {
    width: 320px;
    border-radius: 0;
    height: 568px;
    padding: 75px 16px;
  }

  // .modal-accept_hide

  &_hide {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    border: 0;
    clip: rect(0 0 0 0);
    opacity: 0;
  }

  // .modal-accept__twit

  &__twit {
    min-height: 64px;
  }

  // .modal-accept__title

  &__title {
    color: $blue;
    font-family: $mont;
    font-weight: 700;
    letter-spacing: -0.56px;
    font-size: 28px;
    text-align: left;
    margin: 35px 0 26px 0;

    @include w-768 {
      font-size: 24px;
      letter-spacing: -0.48px;
      width: auto;
      text-align: center;
    }
  }

  // .modal-accept__sub-title

  &__sub-title {
    opacity: 0.87;
    line-height: 25.5px;
    text-align: left;
    margin: 0 10px 32px 30px;
    font-weight: 400;
    max-width: 400px;

    @include w-768 {
      line-height: 22.4px;
      font-size: 14px;
      text-align: center;
      max-width: 277px;
      margin: 0 auto 32px auto;
    }
  }

  // .modal-accept__btn-ok

  &__btn-ok {
    width: 264px;
    height: 59px;
    border-radius: 80px;
    background: $grad;
    margin-bottom: 12px;
    border: none;
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.12);
    color: $white;
    font-size: 17px;
    font-weight: 500;
    cursor: pointer;
    min-height: 57px;

    &:focus {
      border: 2px $blue solid;
      outline: none;
    }

    &:hover {
      background: $yellow;
    }

    @include w-768 {
      margin-left: 5px;
    }
  }

  // .modal-accept__cls-btn-link

  &__cls-btn-link {
    position: absolute;
    top: 35px;
    right: 35px;
    cursor: pointer;
    background: $transparent;
    border: none;

    &:focus {
      outline: none;
      border: 1px $blue solid;
    }

    @include w-768 {
      top: 24px;
      right: 24px;
    }
  }

  &__cls-btn-img:hover {
    opacity: 0.8;
  }
}
