// main: style.scss

*,
*::before,
*::after {
  box-sizing: border-box;
}

// typography

html,
body {
  font-style: normal;
  line-height: 167%;
  color: $black;
  font-family: $sans;
  font-weight: 400;
  font-size: 15px;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

// Убирает свечение при тач событиях у интерактивных элеметов

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

body {
  min-height: 100vh;
  overflow: auto;

  background-color: #ffffff;
}

img,
video,
svg {
  display: block;
  max-width: 100%;
  height: auto;
}

textarea {
  resize: none;
}

// если вы убираете outline - убедитесь, что для всех интерактивных элементов есть кастомный стиль фокуса
/* :focus {
  outline: none;
} */

// firefox placeholder \ invalid fix + ios bdrs
input,
textarea {
  border-radius: 0;

  &::placeholder {
    opacity: 1;
  }

  &:invalid {
    box-shadow: none;
  }
}

select {
  border-radius: 0;
}

// ie11 X removal
input {
  &::-ms-clear,
  &::-ms-reveal {
    display: none;
  }
}

// chrome search X removal
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  appearance: none;
}

// input[number] arrows removal
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;

  appearance: none;
}

input[type="number"] {
  appearance: textfield;
}

// ios inputs reset
select,
textarea,
input:matches([type="email"], [type="number"], [type="password"], [type="search"], [type="tel"], [type="text"], [type="url"]) {
  appearance: none;
}

// visually-hidden

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
}
